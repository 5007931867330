import type { RouteLocation, RouteLocationNormalized } from 'vue-router'

export default defineNuxtRouteMiddleware(async (to) => {
  const user = useSupabaseUser()
  const supabaseClient = useSupabaseClient()

  try {
    if (
      !user.value
      && !isUserAccessingAuthProtectedRoute(to as RouteLocationNormalized)
    )
      return

    if (
      !user.value
      && isUserAccessingAuthProtectedRoute(to as RouteLocationNormalized)
    )
      return logOutAndRedirect(supabaseClient.auth)

    //  User logged in - Check page access
    if (
      user.value
      && !isUserAccessingAuthProtectedRoute(to as RouteLocationNormalized)
    )
      return navigateTo({ path: '/dashboard' })
  }
  catch (error) {
    await logOutAndRedirect(supabaseClient.auth, false)
  }
})

async function logOutAndRedirect(
  supabaseAuthClient: ReturnType<typeof useSupabaseClient>['auth'],
  redirect: boolean = true,
) {
  try {
    await supabaseAuthClient.signOut()
  }
  catch (error) {
  }

  if (redirect)
    return await navigateTo('/login')
}

function isUserAccessingAuthProtectedRoute(
  route: RouteLocation,
) {
  return !['login', 'register'].includes(route.name as string)
}
